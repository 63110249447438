import './App.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Calculate from '@mui/icons-material/Calculate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup'
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AnswerButton from "./AnswerButton"
import StartButton from "./StartButton"
import MathProblem from "./MathProblem"
import useTimer from "easytimer-react-hook"
import OptionSelectionButton from './OptionSelectionButton';

//TODO:
// - Highest score counter.
// - Select number to increase by.
// - Select sign.

const theme = createTheme();

function App() {

  const defaultAnswerText = "?"

  //Timer hook.
  const [timer, isTargetAchieved] = useTimer(
    {startValues: {minutes: 0, seconds: 60}}
  );

  const [score, setScore] = React.useState(0)
  const [problem, setProblem] = React.useState(null)
  const [answer1, setAnswer1] = React.useState(defaultAnswerText)
  const [answer2, setAnswer2] = React.useState(defaultAnswerText)
  const [answer3, setAnswer3] = React.useState(defaultAnswerText)

  const [incrementNumber, setIncrementNumber] = React.useState(2)
  const [symbol, setSymbol] = React.useState('+')

  const generateWrongAnswer = (rightAnswer) => {
    var error = Math.round((Math.random() - .5) * 15)
    if (error == 0) error = 5;
    var wrongAnswer = rightAnswer - error
    if (wrongAnswer < 0) wrongAnswer = 0
    return wrongAnswer
  }

  //TODO: Should move this to MathProblem file; but keep getting unmounted component errors.
  const nextProblem = () => {
    console.log("nextProblem() was called.")
    var n1 = Math.round(Math.random() * 195);
    var n2 = incrementNumber
    var op = symbol
    var newProblem = `${n1} ${op} ${n2}`
    console.log(`Generated new problem: ${newProblem}.`)
    setProblem(`${n1} ${op} ${n2}`)

    //Calculate the right answer and two slightly wrong answers (+/- the real one).
    var rightAnswer = eval(newProblem)
    var wrongAnswer1 = generateWrongAnswer(rightAnswer)
    var wrongAnswer2 = generateWrongAnswer(rightAnswer)

    //Shuffle the answers so they are in different places each time.
    var answers = [rightAnswer, wrongAnswer1, wrongAnswer2]
    const shuffledArray = answers.sort((a, b) => 0.5 - Math.random());
    setAnswer1(shuffledArray[0])
    setAnswer2(shuffledArray[1])
    setAnswer3(shuffledArray[2])
  }

  const incrementSelectionButtons = []
  for (var i = 1; i <= 10; ++i) {
    incrementSelectionButtons.push(
      <OptionSelectionButton setOption={setIncrementNumber} currentValue={incrementNumber} problem={problem}>
        {i}
      </OptionSelectionButton>)
  }

  /* The hook returns an EasyTimer instance and a flag to see if the target has been achieved */
  return (
    <ThemeProvider theme={theme}>

      {/* Header Bar */}
      <AppBar position="relative">
      <Toolbar>
        <Calculate fontSize="large" sx={{ mr: 2 }} />
        <Typography variant="h5" color="inherit" noWrap>
          Simply Kids Math
        </Typography>
      </Toolbar>
    </AppBar>

    <main>

      {/* Main Grid */}
      <Grid container spacing={2} sx={{mt: 3}}>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} sx={{mt: 0}}>

            <Grid item xs={12} align="center">
              <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{border: "0", boxShadow: "0", ml: 2}}>
                {incrementSelectionButtons}
              </ButtonGroup>
              <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{border: "0", boxShadow: "0", ml: 2}}>
                <OptionSelectionButton setOption={setSymbol} currentValue={symbol} problem={problem}>+</OptionSelectionButton>
                <OptionSelectionButton setOption={setSymbol} currentValue={symbol} problem={problem}>-</OptionSelectionButton>
              </ButtonGroup>              
            </Grid>

            <Grid item xs={12}>
              <MathProblem problem={problem} setProblem={setProblem}/>
            </Grid>

            <Grid item xs={12} align="center" sx={{mt: 1}}>
              <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{border: "0", boxShadow: "0"}}>
                <AnswerButton score={score} setScore={setScore} nextProblem={nextProblem} problem={problem}>{answer1}</AnswerButton>
                <AnswerButton score={score} setScore={setScore} nextProblem={nextProblem} problem={problem}>{answer2}</AnswerButton>
                <AnswerButton score={score} setScore={setScore} nextProblem={nextProblem} problem={problem}>{answer3}</AnswerButton>
              </ButtonGroup>
            </Grid>

            <Grid item xs={12} align="center" sx={{mt: 5}}>
              <Divider sx={{color:"black"}}>
                <Chip  align="center" label="Time &amp; Score" />
              </Divider>
              <Typography variant="h5" sx={{mt: 3}}>
                Time Left:
                <Typography variant="h5" display="inline" sx={{ml: 2, color: "red"}}>
                {timer.getTimeValues().toString()}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} align="center" sx={{mt: 1}}>
              <Typography variant="h4" color="green ">
                Score
              </Typography>
              <Typography variant="h4">
                {score}
              </Typography>
            </Grid>

            <Grid item xs={12} align="center">
              <Divider sx={{color:"black"}}>
                <Chip  align="center" label="Controls" />
              </Divider>
              <StartButton timer={timer} setScore={setScore} nextProblem={nextProblem}
                  setProblem={setProblem} problem={problem}/>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
    </main>
  </ThemeProvider>
  )
}

export default App;
