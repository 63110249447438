
import * as React from 'react';
import Button from '@mui/material/Button'
import useSound from 'use-sound';
import startSfx from './sounds/notification_high-intensity.wav';
import stopSfx from './sounds/alert_simple.wav';
import SECONDS from 'easytimer-react-hook'
import PlayArrow from '@mui/icons-material/PlayArrow'


function StartButton(props) {

    const [startSound] = useSound(startSfx);
    const [stopSound] = useSound(stopSfx);

    return (
        <Button variant="contained" aria-label="outlined primary button"
            disabled={props.problem != null}
            sx={{bgcolor: "green", mt: 3, fontSize: "1.5em"}}
            onClick={() => {
                startSound()
                props.setScore(0)
                props.nextProblem()

                props.timer.addEventListener('targetAchieved', function (e) {
                    props.timer.reset()
                    props.timer.stop()
                    stopSound()
                    props.setProblem(null)
                });

                props.timer.start({
                    countdown: true, precision: SECONDS,
                });
            }}>
            <PlayArrow fontSize="large" />&nbsp;Start
        </Button>
    )
}

export default StartButton;
