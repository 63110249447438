import * as React from 'react';
import Button from '@mui/material/Button'
import useSound from 'use-sound';
import startSfx from './sounds/notification_high-intensity.wav';

function AnswerButton(props) {

    const [answerSound] = useSound(startSfx);

    return (
        <Button
            disabled={props.problem == null}
            sx={{ fontSize: '2.5em', mr: "20px"}} value={props.children}
            onClick={() =>
                {
                    answerSound()

                    var isCorrectAnswer = eval(props.problem) == props.children

                    if (isCorrectAnswer) {
                        props.setScore(props.score + 1)
                    }
                    else {
                        props.setScore(props.score - 1)
                    }

                    props.nextProblem()
                }}>
        {props.children}
        </Button>
    )
}

export default AnswerButton;