import * as React from 'react';
import Button from '@mui/material/Button'
import useSound from 'use-sound';
import startSfx from './sounds/notification_high-intensity.wav';

function OptionSelectionButton(props) {

    const [answerSound] = useSound(startSfx);

    const background = props.children == props.currentValue ? "lightblue" : "default"
    const color = props.children == props.currentValue ? "black" : "default"

    return (
        <Button
            disabled={props.problem != null}
            sx={{ fontSize: '1em', background: background, color: color}}
            value={props.children}
            onClick={() =>
                {
                    answerSound()
                    props.setOption(props.children)
                }}>
        {props.children}
        </Button>
    )
}

export default OptionSelectionButton;