
import * as React from 'react';
import Typography from '@mui/material/Typography';

function MathProblem(props) {

    return (
        <Typography variant="h2" align="center">
            {props.problem == null ? "Press Start to Play!" : props.problem + " = ?"}
        </Typography>
    )
}

export default MathProblem;
